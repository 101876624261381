.formlable {
    color: black;
    font-size: 12px;
    font-weight: 400;
  }
  
  .forminput {
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  
  .forminput:focus {
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
    box-shadow: 0 0 0px #000 !important;
  }
  
  .forminput::placeholder{
  
      font-size: 12px;
  }
  .submitinput {
    background-color: #FF5733 !important;
    height: 50px;
    border-radius: 5px !important;
    color: #fff !important;
  }
  Form{
  
      padding: 30px;
  }