.body {
    background-color: #3f4245;
    font-family: plexeina-regular, Helvetica, sans-serif;
    width: 100%;
  
  
  }
  ul{

    list-style-type: none;
  }
  
  .detailsside {
    background-color: #fff;
  
    background: linear-gradient(
        rgba(000, 000, 000, 0.9),
        rgba(000, 000, 000, 0.5)
      ),
      url("../assets/loginimage/otc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .detailsside h2 {
    color: #fff;
    padding-top: 50px;
    font-size: 2.25em;
    font-family: "Circular Bold", plexeina-bold, Helvetica, sans-serif;
  }
  .detailsside p {
    color: antiquewhite;
    padding-top: 30px;
    font-family: "Circular Bold", plexeina-bold, Helvetica, sans-serif;
  }
  .formside {
    color: #fff;
    background-color: #fff;
  
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .formside h2 {
    color: #000;
    padding-top: 50px;
    font-size: 2.25em;
    font-family: "Circular Bold", plexeina-bold, Helvetica, sans-serif;
    text-align: center;
  }
  .formside p {
    color: #000;
    padding-top: 10px;
    font-family: "Circular Bold", plexeina-bold, Helvetica, sans-serif;
    text-align: center;
  }
  .bodyconttainer {
    width: 100%;
  }
  
  .signp {
    margin-top: -40px;
    color: #000!important;
    text-transform: capitalize !important;
  }
  .terms {
    font-size: 14px;
    padding: 30px;
    color: #000 !important;
    text-transform: lowercase !important;
  }
  @media only screen and (max-width: 960px) {
    .detailsside {
      background-color: #fff;
      color: #fff;
  
      height: 300px;
      flex-direction: column;
    
    }
  
    .formside {
      color: #fff;
      background-color: #fff;
      height: auto;
      width: 100%;
  
      flex-direction: column;
    
      border-radius: 10px;
      box-shadow: 2px 2px 2px 1px rgba(245, 244, 244, 0.2);
    }
  }
  