.justify-content-center {
    background-color: #3f4245;
    color: #fff;
    padding: 20px;
  }
  
  .brand {
    color: #fff;
    font-family: plexeina-regular, Helvetica, sans-serif;
    font-size: 25px;
    text-decoration: solid;
    font-weight: 900;
  }
  
  .brand p {
    color: #fff;
    font-family: plexeina-regular, Helvetica, sans-serif;
    font-size: 25px;
    text-decoration: solid;
    font-weight: 900;
  }
  .brand:hover {
    color: antiquewhite;
  }
  
  .formside {
    background-color: #fff;
  }
  